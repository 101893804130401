<template>
    <div>
        <ContentRenderer :value="data">
            <ContentRendererMarkdown :value="data" />
        </ContentRenderer>
    </div>
</template>

<script setup lang="ts">
const route = useRoute();
const {data} = await useAsyncData('page-data', () => queryContent(route.fullPath).findOne());

useContentHead(data);

const {setCrumb} = useBreadcrumbs();

const crumbs = [];

if (data.value._dir) {
    crumbs.push({
        label: data.value._dir.charAt(0).toUpperCase() + data.value._dir.slice(1),
        to: `/${data.value._dir}`
    });
}

crumbs.push({
    label: data.value.title,
    to: data.value._path
})

setCrumb(crumbs);
</script>
